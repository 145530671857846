import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-708b577c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "participate" }
const _hoisted_2 = { class: "participate__in" }
const _hoisted_3 = { class: "participate__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("participate.text1")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("participate.text2")), 1)
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "primary-btn",
        style: {"width":"122px"},
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
      }, _toDisplayString(_ctx.$t("signUp")), 1)
    ])
  ]))
}