import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Participate = _resolveComponent("Participate")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    content: _withCtx(() => [
      _createVNode(_component_Title, null, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("status.completed.alreadyCompletedTitle")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Participate, { onOnClick: _ctx.onClick }, null, 8, ["onOnClick"])
    ]),
    _: 1
  }))
}