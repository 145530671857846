
import { Options, Vue } from "vue-class-component";

import Layout from "@/components/Layout.vue";
import Title from "@/components/Title.vue";
import Participate from "@/components/Participate/index.vue";

@Options({
  components: {
    Layout,
    Title,
    Participate,
  },
})
export default class Completed extends Vue {
  onClick(): void {
    window.open("https://www.medsurvey.com/sites/main/auth/login");
  }
}
