
import { Options, Vue } from "vue-class-component";
@Options({
  components: {},
})
export default class Participate extends Vue {
  onClick(): void {
    this.$emit("onClick");
  }
}
